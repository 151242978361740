import React, { ReactNode } from "react";
import {
  ContentTranslationSponsor,
  SanityExternalCollaborator,
  SanityLicence
} from "../../model/common";
import BlockContent, { BlockContentProps } from "../BlockContent/BlockContent";
import Credits from "../Credits/Credits";
import ExternalCollaboratorCredit from "../ExternalCollaboratorCredit/ExternalCollaboratorCredit";
import styles from "./CreditedContent.module.scss";

interface CreditedContentProps extends BlockContentProps {
  licence?: SanityLicence;
  translationSponsors?: ContentTranslationSponsor[];
  externalCollaborator?: SanityExternalCollaborator;
  // Extra content rendered after the block content.
  children?: ReactNode;
}

/**
 * Block content with license and sponsorship details.
 *
 * Suitable for the main content card in a page.
 */
const CreditedContent = ({
  licence,
  translationSponsors,
  externalCollaborator,
  children,
  ...props
}: CreditedContentProps) => {
  return (
    <div className={styles.root}>
      <BlockContent {...props} />
      {externalCollaborator && (
        <ExternalCollaboratorCredit
          size="lg"
          textVariant="default"
          className={styles.externalCollaborator}
          sponsor={externalCollaborator}
        />
      )}
      {children}
      <Credits translationSponsors={translationSponsors} licence={licence} />
    </div>
  );
};

export default CreditedContent;
